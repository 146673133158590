.fs-1 { font-size: 3rem !important; }
.fs-2 { font-size: 2.5rem !important; }
.fs-3 { font-size: 2rem !important; }
.fs-4 { font-size: 1.5rem !important; }
.fs-5 { font-size: 1.25rem !important; }
.fs-6 { font-size: 1rem !important; }
.fs-7 { font-size: 0.75rem !important; }

a.active {
  color: #0056b3;
}

a {
  /*color:#007bff;*/
  color:#464646;
  text-decoration: underline;
  &:hover {
    color:#007bff;
  }
}


h2 {font-size: 1.75rem;}
h2 {font-size: 1.5rem;}
h3 {font-size: 1.25rem;}

.navbar {
  a {
    text-decoration: none;
  }
  a.nav-hide {
    display: none;
  }

  .navbar-nav li .dropdown-menu li > a {
    text-transform: unset !important;
  }
}

.top-header {
  .logo-block > a > img {
    max-height: none;
    max-width: none;
  }
  .top-social li:first-child > a {
    background:#000;
    color:#FFF;
    padding: 5px 5px 5px 15px;
    border-radius: 5px;
    display: block;
    line-height: 0;
    i {
      display: block;
    }
  }
}

.region-content {
  h2, h3, h4 {
    color: #151515;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    letter-spacing: -0.55px;
    line-height: 1.36;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  ul {
    line-height: 1.8;
  }
}

/**
* FRONT PAGE
**/
body.front {
  .section-header h3 {
    font-size: 1.25rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: inherit;
  }
  .widget UL {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

/**
* NOT FRONT PAGE
**/
// titre
h1.block-title  {
  color: #151515;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: -0.9px;
  margin-bottom: 24px;
  margin-top: 0px;
  padding-bottom: 13px;
  position: relative;
  text-align: center;
  &:before {
    background-color: #e1e1e1;
    content: "";
    height: 2px;
    width: 30px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
  }
}

h3.teaser-title {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.375px;
  line-height: 1.4;
  margin-bottom: 0px;
  margin-top: 0;
  padding-bottom: 0;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  > a {
    color: #151515;
    text-decoration: none;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    -o-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    &:hover {
      color: #717171;
    }
  }
}

// .widget-title {
//   text-align: left;
//   &:before {
//     margin:0;
//   }
// }

.widget_categories {
  ul li {
    &.active/*, &.active-trail*/ {
      background: #e1e1e1;
      &:hover a {
        color:#464646;
      }
    }
    a {
      display: block;
      &.active/*,, &.active-trail*/ {
        background: #e1e1e1
      }
    }
  }
}

.image-box{
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #4679BD;
}
.image-box:before{
  content: "";
  display: block;
  padding-top: 100%;
}
.image-content{
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  text-align: center;
}


.teaser-image-placeholder {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #EEE;
  display: block;
  &:before{
    content: "";
    display: block;
    padding-top: 100%;
  }
  img {
    width: 100%;
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
  }
}

.form-actions .form-submit {
  margin-left: 10px;
  &:first-child {
    margin-left: 0;
  }
}

/* gallery */
.node-experience-full .afcca-images-gallery {
	float:left;
	margin:0 1em 1em 0
}

.afcca-images-gallery {
	float:left;
	margin:0 1em 1em 0
}

.afcca-images-gallery .main {
	position:relative;
	width:180px;
	height:240px;
}

.afcca-images-gallery .main IMG {
	position:absolute;
}

.afcca-images-gallery UL,
.afcca-images-gallery LI {
	margin:0;
	padding:0;
	list-style:none;
}

.afcca-images-gallery UL {
	position:relative;
	left:-4px;
}

.afcca-images-gallery LI {
}

.afcca-images-gallery LI IMG {
	cursor:pointer;
	border:2px solid #FFF;
	float:left;
	margin:4px 2px;
	filter: grayscale(100%);
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
}

.afcca-images-gallery LI.active IMG {
	border-color:#007bff;
	filter: grayscale(0%);
	-webkit-filter: grayscale(0%);
	-moz-filter: grayscale(0%);
	-ms-filter: grayscale(0%);
	-o-filter: grayscale(0%);
}

/* forum */

.submitted {
	font-size: 0.9em;
	color:#808080;
}
.page-forum.not-logged-in .block-menu-block UL LI A {
	background:url(../images/small-lock.gif) no-repeat center right;
	padding-right:12px
}

.page-forum .action-links {
	display:none
}

.page-forum- .action-links {
	display:block
}

#forum .title H2 {
	margin-bottom:4px;
	font-size:18px
}

#forum .title A {
	color:#3C465A;
	text-decoration:none;
}

#forum .posts {
	float:right;
	font-weight:bold;
	font-style:italic;
}

#forum .submitted {
	font-size: 0.8em;
	display:block;
	margin: -2px 0 0 0;
}

#forum .description {
	font-size: 1.1em;
	margin: 0;
}

#forum .message {
	margin: 5px 0 0 0;
}

/* topic / commentaires */

.node-type-forum .content {
	margin:12px auto;
}

#edit-comment-body-und-0-format,
#edit-author--2 {
	display:none;
}

.comment {
	border:1px solid #C6CCD9;
	padding:10px;
	margin:1em 0;
}

.comment H3 {
	margin:0px;
	display:none;
}

.comment .new {
	color:red;
	font-style:italic;
	float:right;
}

.comment .links {
	float:right;
	margin:0
}


/**
* SPECIFIC
**/
BODY.page-adherents:not(.page-adherents-) {
  #page-title {
    display: none;
  }
  .bienfaiteurs H2 {
    margin-top: 0;
  }
}
